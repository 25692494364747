<template>
    <div class="privacy-policy">
        <c-header-policy></c-header-policy>

        <div class="container">
            <h2 class="title">Política de Privacidade</h2>

            <div class="box-content">
                <p class="topic"><strong>POLÍTICA DE PRIVACIDADE DO “MEU PEDIDO ATLAS”.</strong></p>

                <p>
                    A Atlas S/A. tem o compromisso com a privacidade e a segurança dos dados pessoais de seus <strong>Usuários </strong>
                    durante todo o processo de navegação pela plataforma “Meus Pedidos Atlas”. A Política de Privacidade descreve como a 
                    Atlas S/A. coleta, utiliza, armazena e compartilha essas informações.
                </p>

                <p>
                    A Atlas S/A. declara que todos os dados informados pelos <strong>Usuários</strong> têm tratamento de acordo com a Lei Geral de 
                    Proteção de Dados e demais legislações que tratam dessa matéria. No entanto, cabe esclarecer que a maioria dos dados informados no 
                    cadastro para acessar a plataforma são dados referentes às pessoas jurídicas, tais como&nbsp; CNPJ, razão social, e-mail comercial, 
                    entre outros.
                </p>

                <p>
                    Os dados pessoais solicitados são necessários para o funcionamento e desenvolvimento da plataforma “Meus Pedidos Atlas”, além de 
                    serem necessários para a prestação eficiente do serviço disponibilizado no website.
                </p>

                <p>Definição de <strong>Usuários</strong>: Pessoas jurídicas que já são clientes cadastrados da empresa Atlas S/A.;</p>

                <p>&nbsp;</p><p>&nbsp;</p>

                <p class="topic"><strong>Tratamento dos Dados Pessoais.</strong></p>

                <p>
                    Em geral, a Atlas S/A. realiza a coleta de dados na plataforma “Meus Pedidos Atlas” através das informações fornecidas e registradas no cadastro. 
                    A empresa também pode obter acesso aos dados pessoais pelas atividades dos <strong>Usuários</strong> através da plataforma, por meio de 
                    seus dispositivos wireless (endereço IP, o ID, cookies ou outros identificadores de dispositivos). Por fim, a Atlas S/A. poderá 
                    coletar informações de terceiros ( fornecedores).
                </p>

                <p>&nbsp;</p><p>&nbsp;</p>

                <p class="topic"><strong>Métodos de processamento dos dados pessoais coletados:</strong></p>

                <p>
                    O processamento de dados pessoais é realizado por meio de computadores e /ou ferramentas de TI, seguindo procedimentos estritamente 
                    relacionados às finalidades indicadas. A plataforma adotou medidas de segurança apropriadas para impedir o acesso não autorizado, 
                    divulgação, modificação ou destruição de dados pessoais.
                </p>

                <p>&nbsp;</p><p>&nbsp;</p>

                <p class="topic"><strong>Base jurídica para o processamento:</strong></p>

                <p>
                    A Política de Privacidade do “Meu Pedidos Atlas” foi atualizada em 10 de abril de 2.020, com base na Lei Geral de Proteção de Dados - Lei nº 13.709/2.018 - e 
                    demais legislações pertinentes. Periodicamente, poderão ocorrer modificações nesta Política para refletir as novas tecnologias, as práticas do setor e as 
                    exigências regulatórias, entre outros. Caso ocorra alterações materiais nesta Política de Privacidade, será informado com um aviso em destaque na página 
                    inicial da plataforma.
                </p>

                <p>
                    Quando os <strong>Usuários </strong>acessam os serviços disponibilizados na plataforma, através do cadastramento, os dados informados se referem à pessoa jurídica. 
                    Todavia, caso sejam informados dados pessoais estes <strong>são tratados como parte de obrigações pré-contratuais e/ou contratuais.</strong>
                </p>

                <p>
                    O proprietário da plataforma (Atlas S/A.)<strong> </strong>com intuito de otimizar os recursos disponibilizados no website, como por exemplo nas 
                    pesquisas de satisfação, gerenciamento de banco de dados, de contatos e envio de mensagens, infraestrutura, monitoramento e para garantir a segurança 
                    da informação, trata os dados pessoais com <strong>base no legitimo interesse.</strong>
                </p>

                <p>&nbsp;</p><p>&nbsp;</p>

                <p class="topic"><strong>Tempo de armazenamento dos dados:</strong></p>

                <p>
                    A Atlas S/A. armazena as informações dos <strong>Usuários </strong>durante o período necessário para as finalidades apresentadas nos Termos e Condições 
                    de Uso e nesta Política de Privacidade, respeitando o período de retenção de dados determinado pela legislação aplicável.
                </p>

                <p>
                    Os dados dos <strong>Usuários</strong> que se referem à pessoa jurídica, tais como o CNPJ, razão social, e-mail comercial, endereço da sede e 
                    número do registro do cadastro serão mantidos arquivados enquanto durar a relação comercial com a Atlas S/A. Após o término serão armazenados em um 
                    banco de dados pelo período de três (3) anos ou por tempo indeterminado, de acordo com a necessidade da empresa Atlas S/A.
                </p>

                <p>
                    Já as informações de registro coletadas para fins de segurança, manutenção e melhoria da plataforma serão mantidas arquivadas por um (1) ano. 
                    Os dados cadastrais referentes às pessoas jurídicas poderão ser arquivados por tempo indeterminado, de acordo com a necessidade da empresa Atlas S/A.
                </p>

                <p>&nbsp;</p><p>&nbsp;</p>

                <p class="topic"><strong>Compartilhamento dos dados pessoais.</strong></p>

                <p>
                    A Atlas S/A. poderá compartilhar os dados coletados com as demais empresas integrantes do grupo econômico InBetta e com as pessoas responsáveis e 
                    envolvidas com a administração e operação da plataforma.
                </p>

                <p>Fornecedores:</p>

                <p>
                    A Atlas S/A. compartilha dados com os fornecedores (provedores de serviços técnicos, operadoras de correspondências, provedores de hospedagem, 
                    empresas de TI, agências de comunicação) para os fins já mencionados anteriormente neste instrumento. Ao desenvolver atividades com estes terceiros, 
                    a Atlas S/A. se certifica de que estes ofereçam um nível adequado de proteção aos dados pessoais tratados.
                </p>

                <p>Autoridades judiciárias, públicas e/ou governamentais:</p>

                <p>
                    A Atlas S/A. poderá vir a ser obrigada, por lei, em virtude de processos legais e/ou por solicitações de autoridades públicas e governamentais, 
                    a divulgar dados pessoais. A Atlas S/A. também poderá divulgar dados pessoais para fins de segurança nacional, aplicação da lei ou outras questões de 
                    importância pública para proteger os direitos da empresa, &nbsp;investigar fraudes ou proteger as operações, quando a divulgação for necessária ou apropriada.
                </p>

                <p>&nbsp;</p><p>&nbsp;</p>

                <p class="topic"><strong>Os direitos dos Usuários sobre os dados coletados.</strong></p>

                <p>
                    Quando os dados pessoais são tratados em virtude de obrigações contratuais/comerciais ou por legitimo interesse 
                    do Controlador (proprietário da plataforma), os <strong>Usuários</strong> não podem se opor a tal processamento.
                </p>
    
                <p>
                    No entanto, caso seus dados pessoais dos <strong>Usuários</strong> sejam processados &nbsp;para fins de marketing direto (como por exemplo, newsletters, remarketing, etc) 
                    eles podem se opor a esse processamento a qualquer momento, sem fornecer qualquer justificativa. Ademais, para terem ciência se a Atlas S/A. está processando 
                    dados pessoais para fins de marketing direto, os <strong>Usuários </strong>podem contatar a empresa.
                </p>

                <p>
                    <em>
                        Pedidos relativos ao acesso, à atualização e correção de dados pessoais, às políticas e práticas da Atlas S/A. acerca de privacidade e armazenamento de 
                        informações e dados deverão ser direcionados ao Fale Conosco na plataforma ou contatar a Central de Atendimento da Atlas S/A. pelo telefone (51) 3458 5800 ou pelo endereço 
                        eletrônico&nbsp; <a href="mailto:contato.portal@pinceisatlas.com.br">contato.portal@pinceisatlas.com.br</a>
                    </em>
                    <em>.</em>
                </p>

                <p>&nbsp;</p><p>&nbsp;</p>

                <p class="topic"><strong>Segurança e integridade dos dados coletados.</strong></p>

                <p>
                    A Atlas S/A. aplica diferentes medidas de segurança tecnológica e de processos para proteger os dados pessoais coletados, utilizados ou transferidos contra perda, 
                    uso indevido, alteração ou destruição. No entanto, sendo a Internet um ambiente aberto e não protegido, a Atlas S/A. não poderá afirmar que a segurança nas transferências 
                    dos dados pessoais via Internet esta 100% segura. Todavia, a Atlas S/A. oferece um ambiente de protocolo HTTPS (HyperText Transfer Protocol Secure) que permite a transmissão 
                    de dados por meio de conexão criptografada e com certificação digital (SSL).
                </p>

                <p>
                    Toda informação ou dado pessoal prestado à ATLAS S/A<strong>. </strong>é armazenado em servidores ou meios magnéticos de alta segurança, sendo que a 
                    empresa adota as medidas para manter a confidencialidade e a segurança descritas nesta cláusula.
                </p>

                <p>
                    As informações de IP (Internet Protocol) e dos "cookies" serão mantidas pelo tempo que é exigido pela regulamentação vigente um (1) ano. Tais informações serão protegidas, 
                    na medida do razoável, contra acesso não autorizado ou acidental de terceiros.<br>

                    Qualquer utilização da plataforma de modo ilegal (malwares, vazamentos de dados, etc) que possam causar danos e a empresa Atlas S/A. será tratado conforme tipificação penal.<br>
                    Para a segurança das informações coletadas, os <strong>Usuários</strong> devem manter a senha dos cadastros em absoluto sigilo e altera-las regularmente evitando cadastrar 
                    senhas consideradas óbvias, tais como: datas, números sequenciais, números de telefone e placas de veículos, dentre outras. Além disso, devem evitar escrever ou gravar as 
                    senhas de modo que possam estar acessíveis e/ou de maneira que possam ser compreendidas por terceiros.<br>

                    Os <strong>Usuários</strong> devem evitar usar computadores públicos ou compartilhados por outras pessoas. Programas ilegais (Cavalos de Tróia) - 
                    que capturam movimentos do teclado e também podem destruir dados do computador - podem estar instalados fazendo com que as senhas possam ser registradas, 
                    coletadas e posteriormente utilizadas de forma fraudulenta.
                </p>
                
                <p>
                    A Atlas S/A. aconselha aos <strong>Usuários</strong> a não abrirem e-mails não solicitados ou de procedência desconhecida, principalmente, se tiverem arquivos "atachados" (anexados) 
                    que podem conter vírus, Cavalos de Tróia ou outras aplicações que ficam ocultas e permitem a ação de fraudadores sobre suas contas a partir de informações capturadas em 
                    seus computadores. Caso não tenham certeza de quem mandou o e-mail, apague imediatamente.
                </p>
                
                <p>
                    Os <strong>Usuários </strong>devem ter cuidado e somente realizarem downloads (transferência de arquivos para o seu computador) de sites que conhecem e têm certeza de que 
                    são confiáveis. Ademais, é importante não deixar o dispositivo ligado e conectado com a senha de acesso à plataforma, evitando assim a utilização indevida por terceiros. 
                    Sempre que for necessário se ausentar, enquanto estiver acessando a plataforma “Meus Pedidos Atlas” e/ou qualquer de seus serviços, deve ser efetuado o "log off" (desconectar).
                </p>
                
                <p>
                    A empresa Atlas S/A. reitera que as operações efetuadas em qualquer site ou serviços oferecidos que exijam a utilização de senha cadastrada são de 
                    inteira responsabilidade dos <strong>Usuários</strong>. A Atlas S/A. é inteiramente e integralmente isenta de quaisquer consequências decorrentes da quebra de sigilo ou 
                    efetivação de transações não desejadas através do uso indevido das senhas.
                </p>
                
                <p>&nbsp;</p><p>&nbsp;</p>

                <p class="topic"><strong>Sites de terceiros.</strong></p>
                
                <p>
                    A Plataforma “Meus Pedidos Atlas” pode incluir links para sites de terceiros. A Atlas S/A. não representa nem garante a segurança em relação a esses sites. 
                    Os <strong>Usuários</strong> devem estar cientes de que os proprietários e operadores dos websites de terceiros podem coletar, usar ou transferir dados pessoais sob 
                    termos e condições diferentes. Assim sendo, devem se informar sobre as Políticas de Privacidade correspondentes.
                </p>

                <p>&nbsp;</p><p>&nbsp;</p>

                <p class="topic"><strong>Cookies.</strong></p>
                
                <p>
                    Os cookies são pequenos arquivos de texto enviados pelo website ao navegador, que se instalam no disco rígido dos visitantes por um lapso de tempo.
                    Os cookies são utilizados com o fim de conhecer os interesses, o comportamento e a demografia de quem visita a plataforma, com a intenção de compreender
                    melhor suas necessidades e interesses, para oferecer um serviço mais eficaz.
                </p>
                
                <p>
                    A Atlas S/A. utiliza cookies e informações da navegação (sessão do browser) do <strong>Usuário</strong> com o objetivo de traçar um perfil do público 
                    que acessa a plataforma “Meus Pedidos Atlas” e para aperfeiçoar os serviços disponibilizados, tornando-os mais eficientes.
                </p>
                
                <p>&nbsp;</p><p>&nbsp;</p>

                <p>Os <strong>Usuários</strong> podem desabilitar os cookies do seu navegador. Todavia, ao recusar os cookies poderá interferir no uso do website e dos serviços.</p>
                
                <p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p>

                <p>A Atlas S/A. está à disposição através dos canais já informados para dirimir quaisquer dúvidas.</p>
                
                <p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p>

                <p>Política de Privacidade atualizada em 15 de abril de 2.020.</p>
            </div>
        </div>

        <c-footer-policy></c-footer-policy>

        <div class="container">
            <c-footer-links></c-footer-links>
        </div>

        <!-- <c-cookies-banner></c-cookies-banner>

        <c-modal-cookies-preferences></c-modal-cookies-preferences> -->
    </div>
</template>

<script>
    import CHeaderPolicy from "@/components/c-header-policy";
    import CFooterPolicy from "@/components/c-footer-policy";
    // import CCookiesBanner from "@/components/c-cookies-banner";
    // import CModalCookiesPreferences from "@/components/c-modal-cookies-preferences";
    import CFooterLinks from "@/components/c-footer-links";

    export default
    {
        name: 'p-privacy-policy',  
        components: {
            CHeaderPolicy,
            CFooterPolicy,
            // CCookiesBanner,
            // CModalCookiesPreferences,
            CFooterLinks
        },      
    }
</script>