<template>
    <section class="banner-thank-you">
        <div class="container">
            <div class="info-container-thank-you">
                <h3>Obrigado</h3>
                <h4>Por se cadastrar!</h4>
                <p>Em breve você receberá um e-mail com os dados de acesso a plataforma Meus Pedidos.</p>
                <p class="text-green">Clique no link abaixo e conheça todo nosso mix de produtos.</p>
                <a :href="portalProdutosUrl">
                    <button class="btn btn-banner-a">Acesse agora</button>
                </a>
            </div>
        </div>
    </section>
</template>
<script>
    const PORTAL_PRODUTOS_URL = process.env.VUE_APP_PORTAL_PRODUTOS_URL;

    export default
    {
        name: 'v-banner',
        data() {
            return {
                portalProdutosUrl: PORTAL_PRODUTOS_URL,
            }
        }
    }
</script>