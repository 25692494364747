<template>
    <section class="instructions-video">
        <span>Veja como é fácil usar a plataforma</span>
        <div class="instructions-video-player-container" v-if="showPlayer">
            <iframe title="meus pedidos como usar" class="instructions-video-player"
                src="https://www.youtube.com/embed/-aoO0WMc8A0">
            </iframe>
        </div>
    </section>
</template>

<script>   
    import { getCookiePolicy } from "@/utils/cookies"

    export default
    {
        name: 'c-instructions-video',
        data() {
            return {
                showPlayer: false
            }
        },
        mounted() {
            this.showPlayer = getCookiePolicy({name: 'features_cookies'});
        },
    }
</script>