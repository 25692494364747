<template>
    <section class="funciona">
        <div class="container">
            <div class="title">Como funciona o envio <br>de um orçamento</div>
            <div class="passos">
                <img src="@/assets/img/passo_01.png" alt="">
                <img src="@/assets/img/passo_02.png" alt="">
                <img src="@/assets/img/passo_03.png" alt="">
                <img src="@/assets/img/passo_04.png" alt="">
                <div class="finalizacao">
                    <span>Pronto!</span> Seu orçamento foi <br> enviado com sucesso.
                </div>
            </div>
            <div class="title ttu">Você ainda pode!</div>
            <div class="ainda">
                <div class="item">
                    <img src="@/assets/img/ainda_01.png" alt="">
                    <p>Selecionar quantos itens desejar em um único orçamento.</p>
                </div>
                <div class="item">
                    <img src="@/assets/img/ainda_02.png" alt="">
                    <p>salvar seu orçamento em rascunho e retomá-lo quantas vezes precisar.</p>
                </div>
                <div class="item">
                    <img src="@/assets/img/ainda_03.png" alt="">
                    <p>realizar uma busca rápida, por nome ou referência do produto, durante a criação do orçamento.</p>
                </div>
            </div>
            <a href="#register" class="send orange" @click="openInstructionsAlert">Clique aqui e faça seu cadastro</a>
        </div>
    </section>
</template>
<script>
export default {

    methods: {
        openInstructionsAlert() {
            this.$store.dispatch('modalInstructionsAlert/showModal')
        }
    }
}
</script>