<template>
    <div class="thank-you">

        <vue-element-loading :active="loading" is-full-screen />

        <c-header-logo></c-header-logo>

        <v-background></v-background>

        <v-banner></v-banner>

        <v-footer></v-footer>

    </div>
</template>

<script>
    import VueElementLoading from "vue-element-loading";
    import CHeaderLogo from "@/components/c-header-logo";
    import VBackground from "./components/v-background";
    import VBanner from "./components/v-banner";
    import VFooter from "./components/v-footer";

    import { getCookiePolicy } from "@/utils/cookies" 
    import { addGoogleTagManager } from "@/scripts/google-tag-manager"

    export default
    {
        name: 'p-thank-you',
        data: function () {
            return {
                loading: true
            }
        },
        components: {
            CHeaderLogo,
            VBackground,
            VBanner,
            VFooter,
            VueElementLoading
        },
        mounted() {        
           let vue = this

            if (getCookiePolicy({name: 'advertising_cookies'})) {
                addGoogleTagManager()
            }
           
           setTimeout(function() {
               vue.loading = false
           }, 5000)
        }
    }
</script>