import Vue from 'vue'

export function setCookie({ name, value, expire }) {
    Vue.$cookies.set(name, value, expire);
}

export function getCookie({ name }) {
    return Vue.$cookies.get(name)
}

export function removeAllCookies() {
    Vue.$cookies.keys().forEach(cookie => Vue.$cookies.remove(cookie))
}

export function getCookiePolicy({ name }) {
    const cookies = getCookie({ name: 'accept_cookies_meus_pedidos_landing_page' })

    if (!cookies) {
        return 0
    }

    return cookies[name]
}