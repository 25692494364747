<template>
    <section class="access-now">
        <div class="container">
            <div class="headline">
                Mix completo de produtos, informações técnicas, dicas,
                vídeos, treinamentos e promoções.
            </div>
            <div class="sub-headline">Tudo em primeira mão!</div>
            <a href="#register">
                <button class="btn btn-access-now" @click="openInstructionsAlert">Acesse agora</button>
            </a>
        </div>
    </section>
</template>


<script>
export default {

    methods: {
        openInstructionsAlert() {
            this.$store.dispatch('modalInstructionsAlert/showModal')
        }
    }
}
</script>