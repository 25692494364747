<template>
    <section class="perguntas">
        <div class="fundo"></div>
        <div class="container">
            <div class="flex">
                <div class="col-4">
                    <a href="#understand">
                        <img src="@/assets/img/perguntas_icone_01.png" alt="O que é o Meus Pedidos?">
                        <p>O que é o Meus Pedidos?</p>
                    </a>
                </div>
                <div class="col-4">
                    <a href="#howitworks">
                        <img src="@/assets/img/perguntas_icone_02.png" alt="Como solicitar um orçamento?">
                        <p>Como solicitar um orçamento?</p>
                    </a>
                </div>
                <div class="col-4">
                    <a href="#register" @click="openInstructionsAlert">
                        <img src="@/assets/img/perguntas_icone_03.png" alt="Quero me cadastrar!">
                        <p>Quero<br>me cadastrar!</p>
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

    methods: {
        openInstructionsAlert() {
            this.$store.dispatch('modalInstructionsAlert/showModal')
        }
    }
}
</script>