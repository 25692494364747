<template>
    <section class="banner-c">
        <div class="banner-c-container">
            <div class="headline">
                Cadastre-se agora e<br>
                inicie o seu orçamento
            </div>
            <a href="#register">
                <button class="btn btn-banner-c" @click="openInstructionsAlert">Quero acessar a plataforma</button>
            </a>
        </div>
    </section>
</template>

<script>
export default {

    methods: {
        openInstructionsAlert() {
            this.$store.dispatch('modalInstructionsAlert/showModal')
        }
    }
}
</script>