<template>
    <section class="footer-links">
        <div class="container">
            <!-- <a href="#" v-on:click="showModal">Configurações de preferências de privacidade</a> -->
            <!-- <span>|</span> -->
            <router-link :to="{ name: 'page-term-and-conditions'}" target="_blank">Termos e Condições de Uso</router-link>
            <span>|</span>
            <router-link :to="{ name: 'page-privacy-policy'}" target="_blank">Política de Privacidade</router-link>
        </div>
    </section>
</template>

<script>
    const SITE_URL = process.env.VUE_APP_SITE_URL;

    export default
    {
        name: 'c-footer-links',
        data() {
            return {
                siteUrl: SITE_URL,
            }
        },
        methods: {
            showModal(e) {
                e.preventDefault();

                this.$store.dispatch('modalCookiesPreferences/showModal')
            }
        },
    }
</script>