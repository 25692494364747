<template>
    <div>
        <c-banner></c-banner>

        <v-login v-on:dataToRegister="dataToRegister"></v-login>

        <v-questions></v-questions>

        <v-understand id="understand"></v-understand>

        <v-how-it-works id="howitworks"></v-how-it-works>

        <v-register id="register" :register-data="registerData"></v-register>

        <c-footer-links></c-footer-links>

        <!-- <c-cookies-banner></c-cookies-banner>

        <c-modal-cookies-preferences></c-modal-cookies-preferences> -->

        <c-instructions-alert></c-instructions-alert>
    </div>
</template>

<script>
    import VRegister from './components/v-register';
    import VHowItWorks from './components/v-how-it-works';
    import VUnderstand from './components/v-understand';
    import VQuestions from './components/v-questions';
    import VLogin from './components/v-login';
    import CBanner from "@/components/c-banner";
    import CFooterLinks from "@/components/c-footer-links";
    import CInstructionsAlert from "@/components/c-instructions-alert";
    // import CCookiesBanner from "@/components/c-cookies-banner";
    // import CModalCookiesPreferences from "@/components/c-modal-cookies-preferences";
    import { gtag } from "@/scripts/google-tag-manager"
    import { getCookiePolicy } from "@/utils/cookies"

    export default
    {
        name: 'p-home',
        components: {
            CBanner, 
            CFooterLinks, 
            VRegister, 
            VHowItWorks, 
            VUnderstand, 
            VQuestions, 
            VLogin,
            // CCookiesBanner, 
            // CModalCookiesPreferences, 
            CInstructionsAlert
        },
        data() {
            return {
                registerData: {
                    cep:  '',
                    cnpj: ''
                }
            }
        },
        methods: {
            dataToRegister(value)
            {
                this.registerData = value;
            }
        },
        mounted() {
            if (getCookiePolicy({name: 'advertising_cookies'})) {
                gtag('UA-166109652-1');
            } 
        },
    }
</script>