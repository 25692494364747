<template>
    <section id="register" class="register">
        
        <div class="register-title">Cadastre-se</div>

        <form @submit.prevent="register()">
            <div class="field">
                <label for="">Razão Social *</label>
                <input type="text" v-model="formData.company_name" name="company_name" placeholder="Digite a Razão Social" required>                    
            </div>
            <div class="field">
                <label for="">CNPJ *</label>
                <input type="text" v-model="formData.cnpj" name="cnpj" v-mask="masks.cnpj" placeholder="Digite o CNPJ" required>                    
            </div>
            <div class="field">
                <label for="">Nome Contato *</label>
                <input type="text" v-model="formData.contact_name" name="contact_name" placeholder="Digite um nome de contato" required>                    
            </div>
            <div class="field">
                <label for="">E-mail Principal *</label>
                <input type="text" v-model="formData.better_mail" name="better_mail" placeholder="Digite seu principal e-mail" required>                    
            </div>
            <div class="field">
                <label for="">E-mail XML *</label>
                <input type="text" v-model="formData.xml_mail" name="xml_mail" placeholder="Digite o e-mail XML" required>                    
            </div>
            <div class="field">
                <div class="line">
                    <div class="input-flex-1">
                        <label for="">Telefone Comercial *</label>
                        <input type="text" v-model="formData.commercial_phone" name="commercial_phone" v-mask="masks.commercial_phone" placeholder="Digite um telefone" required>                    
                    </div>
                    <div class="input-flex-1 input-margin-left">
                        <label for="">Telefone Celular *</label>
                        <input type="text" v-model="formData.mobile_phone" name="mobile_phone" v-mask="masks.mobile_phone" placeholder="Digite um celular" required>                    
                    </div>
                </div>
            </div>
            <div class="field">
                <label for="">CEP *</label>
                <input type="text" v-model="formData.cep" name="cep" v-mask="masks.cep" placeholder="Digite o CEP" required>                    
            </div>
            <div class="field">
                <label>UF</label>
                <select v-model="formData.uf" name="uf" required>
                    <option value="">Selecione o Estado</option>
                    <option :value="abbreviation" v-for="{name, abbreviation} in states" v-bind:key="abbreviation">{{name}}</option>
                </select>
            </div>
            <div class="field">
                <label for="">Cidade *</label>
                <input type="text" v-model="formData.city" name="city" placeholder="Digite a cidade" required>                    
            </div>
            <div class="field">
                <label for="">Endereço *</label>
                <input type="text" v-model="formData.address" name="address" placeholder="Digite o endereço" required>                    
            </div>
            <div class="field">
                <label for="">Bairro *</label>
                <input type="text" v-model="formData.district" name="district" placeholder="Digite o bairro" required>                    
            </div>
            <div class="field">
                <div class="line">
                    <div class="input-flex-1">
                        <label for="">Número *</label>
                        <input type="text" v-model="formData.number" name="number" placeholder="Digite o número" required>                        
                    </div>
                    <div class="input-flex-1 input-margin-left">
                        <label for="">Complemento *</label>
                        <input type="text" v-model="formData.additional_detail" name="additional_detail" placeholder="Digite o complemento" required>                        
                    </div>
                </div>
            </div>
            <div class="field">
                <div class="checkbox-container">
                    <input v-model="formData.accept_terms" name="accept_terms" id="accept_terms" required="required" type="checkbox">
                    <label for="accept_terms">
                        Ao me cadastrar concordo que meus dados serão armazenados e utilizados para 
                        fins necessários para cadastro e uso da plataforma Meus Pedidos Atlas. 
                        Afirmo que li e concordo com os 
                        <a href="/meuspedidos/termos-e-condicoes-de-uso" class="" target="_blank">
                            Termos e Condições de Uso                            
                        </a> 
                        e a 
                        <a href="/meuspedidos/politica-privacidade" class="" target="_blank">Política de Privacidade.</a>
                    </label>
                </div>
            </div>

            <small class="note">* Preenchimento obrigatório</small>

            <span class="msg success" v-if="success">{{message}}</span>

            <span class="msg error" v-if="error">{{message}}</span>

            <button class="btn btn-register" type="submit">Enviar</button>
        </form>
        <vue-element-loading :active="loading" is-full-screen />
    </section>
</template>

<script>
    const URL = process.env.VUE_APP_API_URL;
    const SITE_URL = process.env.VUE_APP_SITE_URL;

    export default
    {
        name: 'c-register-form',
        props: {
            landingPageKey: String,
            registerData: Object
        },
        data() {
            return {
                siteUrl: SITE_URL,
                success: false,
                error: false,
                loading: false,
                message: '',
                states: [],
                formData: {
                    company_name: '',
                    cnpj: '',
                    contact_name: '',
                    better_mail: '',
                    xml_mail: '',
                    commercial_phone: '',
                    mobile_phone: '',
                    cep: '',
                    uf: '',
                    city: '',
                    address: '',
                    district: '',
                    number: '',
                    additional_detail: '',
                    accept_terms: false,
                    landing_page_key: this.landingPageKey
                },
                masks: {
                    cnpj: '##.###.###/####-##',
                    commercial_phone: '(##) ####-#####',
                    mobile_phone: '(##) ####-#####',
                    cep: '#####-###'
                }
            }
        },
        beforeMount()
        {
            this.getStates();
        },
        methods: {
            register()
            {
                this.success = false;
                this.error   = false;
                this.loading = true;
                
                this.axios.post(URL + '/my-orders/customer-register/register', this.formData)
                    .then(({data}) => {
                        if (data.message) {
                            if (this.landingPageKey === 'A') {
                                this.$router.push({ name: 'page-a-thanks' })
                            } else if (this.landingPageKey === 'B') {
                                this.$router.push({ name: 'page-b-thanks' })
                            } else {
                                this.clearForm();
    
                                this.showMessage('Solicitação enviada com sucesso!', 'success');
                            }
                        }
                    })
                    .catch((error) => {
                        this.validationErrors = [];
                        if (error.response.status === 422) {
                            for (const property in error.response.data) {
                                error.response.data[property].forEach((erro) => {
                                    this.validationErrors.push(erro);
                                });
                            }                          
                            this.showMessage(this.validationErrors[0]);                            
                            return
                        }

                        this.showMessage("Falha ao salvar, verifique os dados informados e tente novamente!", 'error');
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            },
            getStates()
            {
                this.axios.get(URL + '/my-orders/states')
                .then(({ data }) => {
                    this.states = data.data.states;
                })
            },
            showMessage(message, type = 'error')
            {
                this.resetMessage();

                if (type === 'success')
                {
                    this.success = true;
                }
                else
                {
                    this.error = true;
                }

                this.message = message;
            },
            resetMessage()
            {
                this.error   = false;
                this.success = false;
                this.message = '';
            },
            getData(value)
            {
                const CEP = value.replace(/[^\w\s]/gi, '');

                if (CEP.length === 8) {
                    this.axios.get('https://viacep.com.br/ws/'+CEP+'/json/')
                    .then(({data}) => {
                        if (data.cep) {
                            this.formData.uf  = data.uf;
                            this.formData.city  = data.localidade;
                            this.formData.address = data.logradouro;
                            this.formData.district = data.bairro;
                        }
                    })
                }
            },
            clearForm()
            {
                for (const property in this.formData) {
                    this.formData[property] = '';                    
                }
                this.formData.landing_page_key = this.landingPageKey;
            }
        },
        computed: {
            cepValue() {
                return this.formData.cep;
            },
            registerCnpj()
            {
                return this.registerData.cnpj;
            },
            registerCep()
            {
                return this.registerData.cep;
            }
        },
        watch: {
            cepValue(val)
            {
                this.getData(val);
            },
            registerCep(cep)
            {
                this.formData.cep = cep;
            },
            registerCnpj(cnpj)
            {
                this.formData.cnpj = cnpj;
            }
        }
    }
</script>