<template>
    <section class="gain-b">
        <div class="container">
            <c-register-form :landing-page-key="landingPageKey" :register-data="registerData"></c-register-form>
            <div class="icons-container">
                
                <p class="title">O que você<br><span>ganha?</span></p>
                
                <div>
                    <img class="gain-icon" src="@/assets/img/landing_page_a/ganhe_tempo_icon.svg" alt="atlas meus pedidos">
                </div>
                <div>
                    <span class="gain-title">Ganhe tempo</span>
                    <p class="gain-description">Solicite seu orçamento a qualquer hora do dia 
                        e de qualquer lugar.</p>
                </div>
                
                <div>
                    <img class="gain-icon" src="@/assets/img/landing_page_a/facil_acesso_icon.svg" alt="atlas meus pedidos">
                </div>
                <div>
                    <span class="gain-title">Fácil acesso</span>
                    <p class="gain-description">Acesse do computador, tablet ou smartphone.</p>
                </div>
                
                <div>
                    <img class="gain-icon" src="@/assets/img/landing_page_a/loja_atualizada_icon.svg" alt="atlas meus pedidos">
                </div>
                <div>
                    <span class="gain-title">Sua loja<br>atualizada</span>
                    <p class="gain-description">Tenha as últimas novidades Atlas na palma da sua mão.</p>
                </div>
            
            </div>
        </div>
    </section>
</template>

<script>
    import CRegisterForm from "@/components/c-register-form";

    export default
    {
        name: 'v-gain-a',
        components: {
            CRegisterForm
        },
        props: {
            landingPageKey: String,
            registerData: Object
        }
    }
</script>