const state = {
    show: false,
};

const actions = {
    showModal({ state, commit }) {
        commit('setShow', !state.show)
    }
};

const mutations = {
    setShow(state, show) {
        state.show = show;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
}