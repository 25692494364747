<template>
    <div class="landing-page-c">

        <c-header-logo></c-header-logo>
        
        <c-banner></c-banner>
        
        <v-title></v-title>
        
        <v-know></v-know>

        <v-instructions-video></v-instructions-video>

        <v-access-now></v-access-now>

        <v-benefits-icons></v-benefits-icons>
        
        <v-benefits-descriptions></v-benefits-descriptions>

        <c-comments></c-comments>

        <v-banner-c></v-banner-c>

        <c-footer-logo></c-footer-logo>

        <!-- <c-cookies-banner></c-cookies-banner>

        <c-modal-cookies-preferences></c-modal-cookies-preferences> -->

        <c-instructions-alert></c-instructions-alert>

        <!-- <v-login v-on:dataToRegister="dataToRegister"></v-login>

        <v-questions></v-questions>

        <v-understand id="understand"></v-understand>

        <v-how-it-works id="howitworks"></v-how-it-works>

        <v-register id="register" :register-data="registerData"></v-register> -->
    </div>
</template>

<script>
    import CHeaderLogo from "@/components/c-header-logo";
    import CFooterLogo from "@/components/c-footer-logo";
    import CBanner from "@/components/c-banner";
    import CComments from "@/components/c-comments";
    import VTitle from "./components/v-title";
    import VKnow from "./components/v-know";
    import VInstructionsVideo from "./components/v-instructions-video";
    import VAccessNow from "./components/v-access-now";
    import VBenefitsIcons from "./components/v-benefits-icons";
    import VBenefitsDescriptions from "./components/v-benefits-descriptions";
    import VBannerC from "./components/v-banner-c";
    // import CCookiesBanner from "@/components/c-cookies-banner";
    // import CModalCookiesPreferences from "@/components/c-modal-cookies-preferences";
    import CInstructionsAlert from '../../components/c-instructions-alert';
    import { getCookiePolicy } from "@/utils/cookies"

    import { addGoogleTagManager, gtag } from "@/scripts/google-tag-manager"


    export default
    {
        name: 'p-landing-page-1',
        components: {
            CBanner,
            CHeaderLogo,
            CFooterLogo,
            CComments,
            VTitle,
            VKnow,
            VInstructionsVideo,
            VAccessNow,
            VBenefitsIcons,
            VBenefitsDescriptions,
            VBannerC,
            // CCookiesBanner, 
            // CModalCookiesPreferences,
            CInstructionsAlert
        },
        data() {
            return {
                registerData: {
                    cep:  '',
                    cnpj: ''
                }
            }
        },
        mounted() {
            if (getCookiePolicy({name: 'advertising_cookies'})) {
                gtag('UA-166109652-1')
                addGoogleTagManager()                
            }
        },
        methods: {
            dataToRegister(value)
            {
                this.registerData = value;
            }
        }
    }
</script>